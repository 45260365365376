<template>
  <div>
    <v-card>
      <h3>redirect</h3>
      <v-btn @click="token">token</v-btn>
      <v-card>{{ jwt }}</v-card>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "redirect",
  data() {
    return { jwt: "", id: "", username: "", email: "" };
  },
  mounted() {
    // setTimeout(() => this.token(), 100);
    // setTimeout(() => this.$router.push({ name: "All" }), 300);
  },
  methods: {
    token: function () {
      this.$axios
        .get(
          `${this.$urlroot}/auth/google/callback?access_token=${this.$route.query.access_token}`
        )
        .then((res) => {
          if (res.status == 200) {
            this.resp = res.data;
            this.jwt = this.resp.jwt;
            this.id = this.resp.user.id;
            this.username = this.resp.user.username;
            this.email = this.resp.user.email;
            this.$cookies.set("jwt", this.jwt);
            this.$cookies.set("id", this.id);
            this.$cookies.set("username", this.username);
            this.$cookies.set("email", this.email);
          }
        })
        .then();
    },
  },
};
</script>

<style></style>
